html,
body {
  height: 100%;
}

/*
9.4 Attribution.

(a) Content provided to you through the Service may contain the trade names, trademarks, service marks, 
logos, domain names, and other distinctive brand features of Google, its partners, or other third party 
rights holders of content indexed by Google. When Google provides this attribution, you must display it 
as provided through the Service or as described in the Maps APIs Documentation and may not delete or in 
any manner alter these trade names, trademarks, service marks, logos, domain names, and other distinctive 
brand features. https://developers.google.com/maps/terms
*/
/* DIRTY HACK BEGIN */
a[href^="http://maps.google.com/maps"] {
  display: none !important;
}
a[href^="https://maps.google.com/maps"] {
  display: none !important;
}

.gmnoprint a,
.gmnoprint span,
.gm-style-cc {
  display: none;
}
.gmnoprint div {
  background: none !important;
}
.leaflet-control-attribution {
  display: none;
}

.leaflet-control-custom {
  height: 36px;
  width: 36px;
  padding: 8;
}

.leaflet-touch .leaflet-control-custom {
  height: 44px;
  width: 44px;
  padding: 10px;
}

/* DIRTY HACK END */

/* disable all text selection */
body {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.full-height {
  height: 100%;
}

#root {
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  align-content: stretch;
  font-family: 'Helvetica';
}

.main-container {
  flex-grow: 1;
  overflow: hidden;
  transition: margin 0.5s;
}

.grid-layout-container {
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.side-menu {
  height: 100%;
  list-style: none;
  padding: 0px;
  transition: width 0.5s;
}

.side-menu-item-icon {
  width: 20px;
}

.side-menu-header {
  height: 90px;
}

.side-menu-header-text {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.side-menu-header-text h4 {
  margin-top: 10px;
}

.side-menu-header-icon {
  margin: 10px;
}

.side-menu-footer {
  text-align: center;
  position: absolute;
  bottom: 25px;
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.side-menu-footer img {
  width: 150px;
  margin-left: 25px;
  margin-right: 25px;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.side-menu-item {
  white-space: nowrap;
  overflow: hidden;
  padding: 10px;
  text-decoration: none;
}

.side-menu-item-text {
  margin-left: 10px;
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  animation-delay: 0.2s;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  display: initial;
  white-space: nowrap;
  overflow: hidden;
}

/* For Testing animation */
#init-container {
  position: absolute;
  width: 100%;
  height: 100%;
  text-align: center;
}
#init-container:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}
#init-content {
  height: 50%;
  width: 50%;
  background-color: #ffffff;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
}

.gps_ring {
  border: 2px solid #dbff36;
  height: 26px;
  width: 26px;
  margin-top: -28px;
  margin-left: -2px;
  clear: both;
  border-radius: 26px;
  animation: pulsate 2s ease-out;
  animation-iteration-count: infinite;
}

@keyframes pulsate {
  0% {
    -webkit-transform: scale(1, 1);
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(1.5, 1.5);
    opacity: 0;
  }
}

/* end Testing */
.footer-icon-container {
  transition: transform 1s;
  transform-style: preserve-3d;
  cursor: pointer;
}

.is-flipped {
  transform: rotateY(180deg);
}

.footer-icon {
  backface-visibility: hidden;
}

.footer-icon--back {
  transform: rotateY(180deg);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  padding: 10px;
  background-color: white;
}

.wrapper {
  height: 100%;
}

.oc-search-bar > .input-group {
  width: 100%;
}

.list-header-search {
  margin-top: 10px;
  overflow-y: hidden;
  animation: 0.2s heightInc;
}
@keyframes heightInc {
  0% {
    height: 0px;
  }
  100% {
    height: 35px;
  }
}

.panel-container {
  background-color: #ffffffcd;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  border-color: #ddd;
  border: 1px solid transparent;
  box-shadow: 0 1px rgba(0, 0, 0, 0.5);
}

.panel-header {
  background-color: #dddddd;
  height: 40px;
  padding: 8px;
}

.panel-title {
  color: #333333 !important;
  font-size: 18px !important;
}

.dropTarget {
  background-color: #dbff36 !important;
}
.panel-body {
  padding: 0px !important;
  background: #efefef;
  overflow-x: hidden;
}

.panel-body-scrollable {
  overflow-y: scroll;
  overflow-x: hidden;
}

.list-item-highlighted {
  background-color: rgba(255, 0, 0, 0.26) !important;
}
.list-item-selected {
  background-color: rgba(1, 128, 246, 0.507) !important;
}

.list-item-details {
  color: rgb(91, 94, 97);
  font-size: 12px;
  word-break: break-all;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
}

.list-item-details-highlighted {
  color: #480000;
}

.list-item-name {
  color: #111111;
  margin-bottom: 08px !important;
  font-size: 14px;
  white-space: pre-wrap;
}

.organization-button {
  background-color: Transparent !important;
  background-repeat: no-repeat !important;
  border: none !important;
  cursor: pointer !important;
  overflow: hidden !important;
  outline: none !important;
}

.organization-button > option {
  color: #555 !important;
}

.organization-live-tag {
  margin-right: 20px;
  margin-top: 5px;
  background: #50e039;
  padding: 3px;
  border-radius: 4px;
  color: white;
}

.organization-close-button {
  color: #dbff36;
  cursor: pointer;
  margin-top: 7px;
  margin-left: 5px;
  font-size: 18px;
}

.thumb-horizontal {
  background-color: #dbff36;
}

.main-content {
  font-family: 'Helvetica';
  padding: 10px;
  overflow-x: hidden;
  overflow-y: hidden;
  white-space: nowrap;
  position: inherit;
  height: 100%;
}
.main-content-ie {
  font-family: 'Helvetica';
  padding: 10px;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  position: inherit;
  height: 100%;
}
.main-content-events {
  font-family: 'Helvetica';
  padding: 10px;
  overflow-x: auto;
  overflow-y: auto;
}
.main-content-settings {
  font-family: 'Helvetica';
  padding: 10px;
  overflow-x: hidden;
  overflow-y: hidden;
  white-space: nowrap;
  position: inherit;
}
.card {
  display: inline-block;
  margin-right: 10px;
  vertical-align: top;
  height: 100%;
  /*transition: width 200ms ease-in-out;*/
}

.question-dialog {
  font-family: 'Helvetica';
}

.question-dialog-header {
  background: #e5e5e5;
  height: 40px;
  width: 100%;
}

.question-dialog-content {
  padding: 15px;
  height: 100%;
  display: table-row;
  position: absolute;
  top: 40px;
  bottom: 52px;
  left: 0px;
  right: 0;
  overflow: auto;
  padding: 25px;
}

.question-dialog-footer {
  background: #e5e5e5;
  height: 52px;
  padding: 10px;
  bottom: 0;
  position: absolute;
  width: 100%;
}

.login-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  /* Preserve aspet ratio */
  min-width: 100%;
  min-height: 100%;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover !important;
}

.login-div-top {
  z-index: 2;
  position: fixed;
  top: 45%;
  left: 35%;
  width: 20em;
  min-height: 23em;
  padding-bottom: 20px;
  margin-top: -9em;
  margin-left: -15em;
  background-color: #f3f3f3;
  border-radius: 20px;
}
.login-div-top-mobile {
  z-index: 2;
  position: fixed;
  top: 45%;
  width: 20em;
  min-height: 23em;
  padding-bottom: 20px;
  margin-top: -9em;
  margin-left: 20px;
  background-color: #f3f3f3;
  border-radius: 20px;
}

.login-div-bottom {
  z-index: 1;
  position: fixed;
  top: 50%;
  left: 30%;
  width: 40em;
  height: 18em;
  margin-top: -9em;
  margin-left: -15em;
  background-color: rgba(64, 64, 64, 0.7);
  border-radius: 20px;
}

.login-logo {
  width: 150px;
  margin: 20px;
}

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  /* Set the fixed height of the footer here */
  height: 40px;
  /* background-color: #34495E; */
}

.map {
  padding: 0px !important;
}

.leaflet-map-info-tag {
  background-color: #ffffff;
  border-radius: 5px;
  float: right;
}

.leaflet-map-info-tag-icon {
  width: 36px;
  height: 36px;
  margin-left: 5px;
  margin-top: 5px;
}

.leaflet-map-info-tag-icon-safari {
  width: 30px;
  height: 30px;
  margin-left: 5px;
  margin-top: 5px;
}

.leaflet-map-info-tag-extended {
  background-color: #ffffff;
  border-radius: 5px;
  width: 200px;
  float: right;
}

.leaflet-map-info {
  background-color: #ffffff;
  width: 300px;
  border-radius: 5px;
  padding: 8px;
}

.leaflet-map-info-extended {
  background-color: #ffffff;
  width: 300px;
}

.leaflet-map-info-details {
  margin-bottom: 0px;
}

.leaflet-map-info-last-seen {
  color: gray;
}

.dashboard-events {
  position: sticky;
  z-index: 1;
}

.dashboard-events-extended {
  position: sticky;
  z-index: 1;
}

.dashboard-material-orders {
}

.edit-dialog {
  font-family: 'Helvetica';
  background-color: #ffffffcd;
}

.edit-dialog-header {
  background-color: #dddddd;
  height: 40px;
  padding: 8px;
}

.edit-dialog-title {
  color: #333333 !important;
  font-size: 18px !important;
}

.edit-dialog-content {
  padding: 25px;
  height: 80%;
  overflow-y: auto;
}

.edit-dialog-footer {
  background: #e5e5e5;
  height: 52px;
  padding: 10px;
}

.edit-event-dialog {
  width: 100%;
  font-family: 'Helvetica';
  height: 100%;
}

.edit-event-dialog-header {
  background: #e5e5e5;
  height: 40px;
}

.edit-event-dialog-content {
  padding: 25px;
  height: 90%;
}

.edit-event-dialog-footer {
  background: #e5e5e5;
  height: 52px;
  padding: 10px;
  bottom: 0;
  position: absolute;
  width: 100%;
}

.edit-user-dialog {
  font-family: 'Helvetica';
}

.edit-user-dialog-header {
  background: #e5e5e5;
  height: 40px;
}

.edit-user-dialog-content {
  position: absolute;
  top: 40px;
  bottom: 52px;
  left: 0px;
  right: 0px;
  overflow: auto;
  padding: 25px;
}

.edit-user-dialog-footer {
  background: #e5e5e5;
  height: 52px;
  padding: 10px;
  bottom: 0;
  position: absolute;
  width: 100%;
}

.edit-materialOrder-dialog {
  font-family: 'Helvetica';
}

.edit-materialOrder-dialog-header {
  background: #e5e5e5;
  height: 40px;
}

.edit-materialOrder-dialog-content {
  padding: 25px;
}

.edit-materialOrder-dialog-footer {
  background: #e5e5e5;
  height: 52px;
  padding: 10px;
}

.edit-materialOrder-dialog-map {
  height: 200px;
  overflow: hidden;
}

.edit-qr-dialog {
  font-family: 'Helvetica';
}

.edit-qr-dialog-header {
  background: #e5e5e5;
  height: 40px;
}

.edit-qr-dialog-content {
  overflow-y: auto;
  padding: 25px;
}

.edit-qr-dialog-footer {
  background: #e5e5e5;
  height: 52px;
  padding: 10px;
}

.edit-qr-dialog-map {
  height: 200px;
  overflow: hidden;
}

.edit-briefing-dialog {
  font-family: 'Helvetica';
}

.edit-briefing-dialog-header {
  background: #e5e5e5;
  height: 40px;
}

.edit-briefing-dialog-content {
  padding: 25px;
}

.edit-briefing-dialog-footer {
  background: #e5e5e5;
  height: 52px;
  padding: 10px;
}

.edit-edit-briefing-dialog-text-area {
  height: 200px !important;
  overflow: hidden;
  -webkit-user-select: text;
}

.edit-patient-dialog {
  font-family: 'Helvetica';
}

.edit-patient-dialog-header {
  background: #e5e5e5;
  height: 40px;
}

.edit-patient-dialog-content {
  padding: 25px;
  height: 500px;
  overflow-y: scroll;
}

.edit-patient-dialog-footer {
  background: #e5e5e5;
  height: 52px;
  padding: 10px;
}

.edit-patient-dialog-map {
  height: 200px;
  overflow: hidden;
}

.edit-incident-dialog {
  font-family: 'Helvetica';
  width: 600px;
}

.edit-incident-dialog-header {
  background: #e5e5e5;
  height: 40px;
}

.edit-incident-dialog-content {
  padding: 25px;
  height: 500px;
  overflow-y: scroll;
}

.edit-incident-dialog-map {
  height: 200px;
  overflow: hidden;
}

.close-button {
  font-size: 20px !important;
}

.list-icon {
  -webkit-transform: scale(0.5);
  /* Saf3.1+, Chrome */
  -moz-transform: scale(0.5);
  /* FF3.5+ */
  -ms-transform: scale(0.5);
  /* IE9 */
  -o-transform: scale(0.5);
  /* Opera 10.5+ */
  transform: scale(0.5);
  /* IE6–IE9 */
  filter: progid:DXImageTransform.Microsoft.Matrix(M11=0.9999619230641713, M12=-0.008726535498373935, M21=0.008726535498373935, M22=0.9999619230641713,SizingMethod='auto expand');
}

.table-header {
  font-size: 14px;
  color: #333333;
  font-weight: bold;
}

.form-control-label {
  text-align: left !important;
}

/* the divs containing the trash-bin icons */

.pull-right.col-md-3.text-center {
  margin-left: -30px;
}

/*CHAT */

.chat-input-container {
  margin: 5px;
}

.message-list {
  overflow-y: scroll;
  width: '100%';
  margin-top: 6px;
}
.message-text {
  overflow-x: hidden;
  word-wrap: break-word;
  white-space: pre-line;
}
.message-container {
  padding-right: 0px !important;
  padding-left: 5px !important;
}

.message-container-from-me {
  padding-right: 5px !important;
  padding-left: 0px !important;
}

.message {
  background-color: #fff;
  border-radius: 5px;
  /*box-shadow: 0 0 6px #B2B2B2;*/
  display: inline-block;
  padding-top: 0px;
  padding-right: 15px;
  padding-bottom: 0px;
  padding-left: 15px;
  position: relative;
  vertical-align: top;
  margin: 5px 45px 5px 20px;
  width: 99%;
}

.message-from-me {
  background-color: #edffee;
  border-radius: 5px;
  /*box-shadow: 0 0 6px #9bbef7;*/
  display: inline-block;
  padding-top: 0px;
  padding-right: 15px;
  padding-bottom: 0px;
  padding-left: 15px;
  position: relative;
  vertical-align: top;
  margin: 5px 20px 5px 45px;
  width: 99%;
}

.message-enter {
  opacity: 0;
  transform: translate(-250px, 0);
  transform: translate3d(-250px, 0, 0);
}

.message-enter.message-enter-active {
  opacity: 1;
  transition: opacity 1s ease;
  transform: translate(0, 0);
  transform: translate3d(0, 0, 0);
  transition-property: transform, opacity;
  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0.175, 0.665, 0.32, 1), linear;
}

.new-messages {
  z-index: 200;
  position: absolute;
  padding: 0px 10px;
  margin-left: 33%;
  border-radius: 10px;
  background: #fdb125;
  cursor: pointer;
  box-shadow: 0 0 6px #464646;
}

.avatar {
  border-radius: 50%;
  border: 2px solid black;
  width: 25px;
  height: 25px;
  text-align: center;
  vertical-align: middle;
  font-size: 12px;
  line-height: 25px;
  font-family: sans-serif;
}

.leaflet-div-icon-container {
  border: 0px;
}

.leaflet-div-icon-avatar {
  border-radius: 50%;
  border: 2px solid black;
  width: 25px;
  height: 25px;
  text-align: center;
  vertical-align: middle;
  font-size: 12px;
  line-height: 25px;
  font-family: sans-serif;
}
.ReactModal__Body--open {
  overflow-y: hidden;
}

.div-table-content {
  height: 300px;
  overflow-x: hidden;
  overflow-y: auto;
}

.import-user-dialog-content {
  padding: 15px;
  margin-bottom: 40px;
}

.import-user-dialog-footer button {
  margin-bottom: 15px;
  margin-right: 15px;
}

.dropzone {
  width: 100%;
  height: 250px;
  border-style: dashed;
  border-color: #34495e;
  border-width: 3px;
  border-radius: 15px;
  padding: 15px;
  background-color: rgba(52, 73, 94, 0.2);
  cursor: pointer;
  margin-bottom: 50px;
}

.places-form-group {
  z-index: 1001;
  position: relative;
}

.my-autocomplete-container {
  width: 50%;
  position: absolute;
  top: 100%;
  left: 0;
  background: #fff;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.12), 0 1px 4px rgba(0, 0, 0, 0.24);
  z-index: 1000000;
  margin-left: 15px;
}

.places-input {
  min-width: 250px;
  display: block;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out 0.15s,
    -webkit-box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s,
    -webkit-box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s,
    -webkit-box-shadow ease-in-out 0.15s;
}

.sr-only {
  display: none;
}

.marker-container {
  float: left;
  background-color: #ffffff;
  border-color: #ffffff;
  border-radius: 10px;
  border-width: 3px;
  border-style: solid;
}

.leaflet-map-info-icon {
  margin: 10px;
  width: 27px;
  height: 35px;
}

.gis-div-icon {
  font-size: 12px;
  border: none;
  background-color: transparent;
}
.gis-div-icon-event {
  font-size: 17px;
  border: none;
  background-color: transparent;
  width: 500px !important;
}

.dropTarget-is-over {
  background-color: #ffc400 !important;
}

.edit-dialog-section {
  border: #545557;
  border-width: 2px;
  border-top-style: solid;
  margin-bottom: 12px;
}

.base-picker-button {
  padding-right: 0px !important;
  background-color: #ffffff !important;
  background-repeat: no-repeat !important;
  border: none !important;
  cursor: pointer !important;
  overflow: hidden !important;
  outline: none !important;
  color: rgb(0, 0, 0) !important;
  min-width: 200px;
  min-height: 25px;
}
.base-picker-button > span {
  float: right;
  margin-right: 10px;
  margin-top: 5px;
}

.edit-dialog-content {
  margin-bottom: 20px;
}

.autocomplete-tags {
  padding: 0px !important;
}

/* make the drop down menu in the event edit dialog map scrollable */
.theme_dropdownSuggestions__1bl {
  max-height: 100px;
  overflow: auto;
  width: 80% !important;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4), 0 6px 20px 0 rgba(0, 0, 0, 0.39);
}

.content-scroll-wrap {
  position: absolute;
  top: 40px;
  bottom: 52px;
  left: 0px;
  right: 0;
  overflow: auto;
  padding: 25px;
}

.content-scroll {
  position: relative;
  height: 100%;
}

.dyn-content {
  display: 'flex';
  height: 100%;
}

.edit-incident-content {
  display: table-row;
  height: 100%;
}

.edit-incident-content-scroll-wrap {
  position: absolute;
  top: 40px;
  bottom: 52px;
  left: 0px;
  right: 0;
  overflow: auto;
  padding: 25px;
}

.edit-incident-content-scroll {
  position: relative;
  height: 100%;
}

.edit-incident-dialog-footer {
  background: #e5e5e5;
  height: 52px;
  padding: 10px;
  bottom: 0;
  position: absolute;
  width: 100%;
}

.edit-dialog-patient {
  font-family: 'Helvetica';
}

.edit-patient-content {
  display: table-row;
  height: 100%;
}

.edit-patient-content-scroll-wrap {
  position: absolute;
  top: 40px;
  bottom: 52px;
  left: 0px;
  right: 0;
  overflow: auto;
  padding: 25px;
}

.edit-patient-content-scroll {
  position: relative;
  height: 100%;
}

.edit-patient-dialog-footer {
  background: #e5e5e5;
  height: 52px;
  padding: 10px;
  bottom: 0;
  position: absolute;
  width: 100%;
}

.leaflet-draw-section {
  position: relative;
}
.leaflet-draw-toolbar {
  margin-top: 12px;
}
.leaflet-draw-toolbar-top {
  margin-top: 0;
}
.leaflet-draw-toolbar-notop a:first-child {
  border-top-right-radius: 0;
}
.leaflet-draw-toolbar-nobottom a:last-child {
  border-bottom-right-radius: 0;
}
.leaflet-draw-toolbar a {
  background-image: url('images/spritesheet.png');
  /*background-image:linear-gradient(transparent,transparent), url('images/spritesheet.svg');*/
  background-repeat: no-repeat;
  background-size: 300px 30px;
  background-clip: padding-box;
}
.leaflet-retina .leaflet-draw-toolbar a {
  background-image: url('images/spritesheet-2x.png');
  /*background-image:linear-gradient(transparent,transparent),url('images/spritesheet.svg')*/
}
.leaflet-draw a {
  display: block;
  text-align: center;
  text-decoration: none;
}
.leaflet-draw a .sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
.leaflet-draw-actions {
  display: none;
  list-style: none;
  margin: 0;
  padding: 0;
  position: absolute;
  left: 26px;
  top: 0;
  white-space: nowrap;
}
.leaflet-touch .leaflet-draw-actions {
  left: 32px;
}
.leaflet-right .leaflet-draw-actions {
  right: 26px;
  left: auto;
}
.leaflet-touch .leaflet-right .leaflet-draw-actions {
  right: 32px;
  left: auto;
}
.leaflet-draw-actions li {
  display: inline-block;
}
.leaflet-draw-actions li:first-child a {
  border-left: 0;
}
.leaflet-draw-actions li:last-child a {
  -webkit-border-radius: 0 4px 4px 0;
  border-radius: 0 4px 4px 0;
}
.leaflet-right .leaflet-draw-actions li:last-child a {
  -webkit-border-radius: 0;
  border-radius: 0;
}
.leaflet-right .leaflet-draw-actions li:first-child a {
  -webkit-border-radius: 4px 0 0 4px;
  border-radius: 4px 0 0 4px;
}
.leaflet-draw-actions a {
  background-color: #919187;
  border-left: 1px solid #aaa;
  color: #fff;
  font: 11px/19px 'Helvetica Neue', Arial, Helvetica, sans-serif;
  line-height: 28px;
  text-decoration: none;
  padding-left: 10px;
  padding-right: 10px;
  height: 28px;
}
.leaflet-touch .leaflet-draw-actions a {
  font-size: 12px;
  line-height: 30px;
  height: 30px;
}
.leaflet-draw-actions-bottom {
  margin-top: 0;
}
.leaflet-draw-actions-top {
  margin-top: 1px;
}
.leaflet-draw-actions-top a,
.leaflet-draw-actions-bottom a {
  height: 27px;
  line-height: 27px;
}
.leaflet-draw-actions a:hover {
  background-color: #a0a098;
}
.leaflet-draw-actions-top.leaflet-draw-actions-bottom a {
  height: 26px;
  line-height: 26px;
}
.leaflet-draw-toolbar .leaflet-draw-draw-polyline {
  background-position: -2px -2px;
}
.leaflet-touch .leaflet-draw-toolbar .leaflet-draw-draw-polyline {
  background-position: 0 -1px;
}
.leaflet-draw-toolbar .leaflet-draw-draw-polygon {
  background-position: -31px -2px;
}
.leaflet-touch .leaflet-draw-toolbar .leaflet-draw-draw-polygon {
  background-position: -29px -1px;
}
.leaflet-draw-toolbar .leaflet-draw-draw-rectangle {
  background-position: -62px -2px;
}
.leaflet-touch .leaflet-draw-toolbar .leaflet-draw-draw-rectangle {
  background-position: -60px -1px;
}
.leaflet-draw-toolbar .leaflet-draw-draw-circle {
  background-position: -92px -2px;
}
.leaflet-touch .leaflet-draw-toolbar .leaflet-draw-draw-circle {
  background-position: -90px -1px;
}
.leaflet-draw-toolbar .leaflet-draw-draw-marker {
  background-position: -122px -2px;
}
.leaflet-touch .leaflet-draw-toolbar .leaflet-draw-draw-marker {
  background-position: -120px -1px;
}
.leaflet-draw-toolbar .leaflet-draw-draw-circlemarker {
  background-position: -273px -2px;
}
.leaflet-touch .leaflet-draw-toolbar .leaflet-draw-draw-circlemarker {
  background-position: -271px -1px;
}
.leaflet-draw-toolbar .leaflet-draw-edit-edit {
  background-position: -152px -2px;
}
.leaflet-touch .leaflet-draw-toolbar .leaflet-draw-edit-edit {
  background-position: -150px -1px;
}
.leaflet-draw-toolbar .leaflet-draw-edit-remove {
  background-position: -182px -2px;
}
.leaflet-touch .leaflet-draw-toolbar .leaflet-draw-edit-remove {
  background-position: -180px -1px;
}
.leaflet-draw-toolbar .leaflet-draw-edit-edit.leaflet-disabled {
  background-position: -212px -2px;
}
.leaflet-touch .leaflet-draw-toolbar .leaflet-draw-edit-edit.leaflet-disabled {
  background-position: -210px -1px;
}
.leaflet-draw-toolbar .leaflet-draw-edit-remove.leaflet-disabled {
  background-position: -242px -2px;
}
.leaflet-touch
  .leaflet-draw-toolbar
  .leaflet-draw-edit-remove.leaflet-disabled {
  background-position: -240px -2px;
}
.leaflet-mouse-marker {
  background-color: #fff;
  cursor: crosshair;
}
.leaflet-draw-tooltip {
  background: #363636;
  background: rgba(0, 0, 0, 0.5);
  border: 1px solid transparent;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  color: #fff;
  font: 12px/18px 'Helvetica Neue', Arial, Helvetica, sans-serif;
  margin-left: 20px;
  margin-top: -21px;
  padding: 4px 8px;
  position: absolute;
  visibility: hidden;
  white-space: nowrap;
  z-index: 6;
}
.leaflet-draw-tooltip:before {
  border-right: 6px solid black;
  border-right-color: rgba(0, 0, 0, 0.5);
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  content: '';
  position: absolute;
  top: 7px;
  left: -7px;
}
.leaflet-error-draw-tooltip {
  background-color: #f2dede;
  border: 1px solid #e6b6bd;
  color: #b94a48;
}
.leaflet-error-draw-tooltip:before {
  border-right-color: #e6b6bd;
}
.leaflet-draw-tooltip-single {
  margin-top: -12px;
}
.leaflet-draw-tooltip-subtext {
  color: #f8d5e4;
}
.leaflet-draw-guide-dash {
  font-size: 1%;
  opacity: 0.6;
  position: absolute;
  width: 5px;
  height: 5px;
}
.leaflet-edit-marker-selected {
  background-color: rgba(254, 87, 161, 0.1);
  border: 4px dashed rgba(254, 87, 161, 0.6);
  -webkit-border-radius: 4px;
  border-radius: 4px;
  box-sizing: content-box;
}
.leaflet-edit-move {
  cursor: move;
}
.leaflet-edit-resize {
  cursor: pointer;
}
.leaflet-oldie .leaflet-draw-toolbar {
  border: 1px solid #999;
}

button.organization-button-with-padding span {
  margin-top: 13px !important;
  margin-left: 5px !important;
}

.csv-import-ckb {
  width: 50px;
}

.csv-import-ckb-0 {
  width: 150px;
  word-break: break-all;
}

.csv-import-ckb-1 {
  width: 150px;
  word-break: break-all;
}
.csv-import-ckb-2 {
  width: 180px;
  word-break: break-all;
}
.csv-import-ckb-3 {
  width: 200px;
  word-break: break-all;
}
.csv-import-ckb-4 {
  width: 150px;
  word-break: break-all;
}
.csv-import-ckb-5 {
  width: 150px;
  word-break: break-all;
}
.csv-import-ckb-6 {
  width: 150px;
  word-break: break-all;
}

.csv-import-ckb-7 {
  width: 150px;
  word-break: break-all;
}

.heatmap-slider-container {
  position: absolute !important;
  width: 100% !important;
  padding-left: 12px !important;
  padding-right: 12px !important;
  padding-bottom: 50px !important;
  z-index: 1 !important;
  bottom: 0 !important;
}

.heatmap-slider-wrapper {
  background-color: #fff !important;
  border-radius: 4px !important;
  border-style: solid;
  border-width: 1px;
}

.heatmap-slider-inner-wrapper {
  align-items: center !important;
  display: flex !important;
  flex-direction: row !important;
}

.heatmap-slider-buttons {
  padding: 6px;
}

.heatmap-slider-slider {
  padding: 6px;
  flex-grow: 1 !important;
  min-width: 0;
  display: block;
  max-width: 100%;
}
.heatmap-slider-time {
  padding-left: 12px !important;
  padding-right: 12px !important;
  padding-top: 3px !important;
  padding-bottom: 3px !important;
  display: block;
  max-width: 100%;
}

.heatmap-control-container {
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.1) !important;
  background-color: #fff !important;
  width: 240px !important;
  padding: 12px;
  margin: 12px;
  z-index: 5 !important;
  right: 0;
  top: 10;
  position: absolute !important;
  border-radius: 4px !important;
  font-size: 12px;
  line-height: 18px;
}

.heatmap-layer-container {
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.1) !important;
  background-color: #fff !important;
  width: 240px !important;
  padding: 12px;
  margin: 12px;
  z-index: 5 !important;
  left: 0;
  top: 10;
  position: absolute !important;
  border-radius: 4px !important;
  font-size: 12px;
  line-height: 18px;
}

.heatmap-layer-containere-small {
  -webkit-box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.1) !important;
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.1) !important;
  background-color: #fff !important;
  width: 50px !important;
  height: 50px;
  padding-top: 3px;
  margin: 12px;
  padding-right: 11px;
  z-index: 5 !important;
  left: 0;
  top: 10;
  position: absolute !important;
  border-radius: 4px !important;
}

.range-slider {
  width: 100%;
  height: 16px;
  margin-top: 3px;
  margin-bottom: 10px;
  border: 1px solid grey;
  background: #ddd;
}
.bar {
  top: 0px;
  height: 14px;
}
.bar-1 {
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(rgba(10, 0, 0, 0)),
    color-stop(rgb(84, 0, 0)),
    color-stop(rgb(157, 0, 0)),
    color-stop(rgb(233, 0, 0)),
    color-stop(rgb(255, 52, 0)),
    color-stop(rgb(255, 128, 0)),
    color-stop(rgb(255, 201, 0)),
    color-stop(rgb(255, 255, 34)),
    color-stop(rgb(255, 255, 144)),
    to(rgb(255, 255, 255))
  );
  background: -webkit-linear-gradient(
    left,
    rgba(10, 0, 0, 0),
    rgb(84, 0, 0),
    rgb(157, 0, 0),
    rgb(233, 0, 0),
    rgb(255, 52, 0),
    rgb(255, 128, 0),
    rgb(255, 201, 0),
    rgb(255, 255, 34),
    rgb(255, 255, 144),
    rgb(255, 255, 255)
  );
  background: -o-linear-gradient(
    left,
    rgba(10, 0, 0, 0),
    rgb(84, 0, 0),
    rgb(157, 0, 0),
    rgb(233, 0, 0),
    rgb(255, 52, 0),
    rgb(255, 128, 0),
    rgb(255, 201, 0),
    rgb(255, 255, 34),
    rgb(255, 255, 144),
    rgb(255, 255, 255)
  );
  background: linear-gradient(
    to right,
    rgba(10, 0, 0, 0),
    rgb(84, 0, 0),
    rgb(157, 0, 0),
    rgb(233, 0, 0),
    rgb(255, 52, 0),
    rgb(255, 128, 0),
    rgb(255, 201, 0),
    rgb(255, 255, 34),
    rgb(255, 255, 144),
    rgb(255, 255, 255)
  );
}
.range-slider .handle {
  width: 3px;
  height: 14px;
  line-height: 14px;
  background: #000;
}

.range-slider .handle {
  font-size: 0.9em;
  text-align: center;
  background-color: black;
  color: black;
  cursor: pointer;
}

.range-slider .handle {
  width: 3px;
  height: 14px;
  line-height: 14px;
  background: #000;
}

.range-slider .handle .label {
  margin-top: 16px;
  margin-left: -150%;
  color: #000;
  display: block !important;
}

.hidden {
  visibility: hidden;
}

.markerCluster-pin {
  border-radius: 50% 50% 50% 0;
  border: 4px solid #fff;
  width: 40px;
  height: 40px;
  transform: rotate(-45deg);
  cursor: pointer;
}

.list-header {
  position: relative;
  background: #eeeeef;
  min-height: 40px;
  padding: 8px;
  font-weight: 700;
}

.edit-user-dialog-multiselect {
  margin-right: 40px;
  margin-top: 5px;
}

.pulse {
  -webkit-animation: pulse 1s infinite ease-in-out;
  -o-animation: pulse 1s infinite ease-in-out;
  -ms-animation: pulse 1s infinite ease-in-out;
  -moz-animation: pulse 1s infinite ease-in-out;
  animation: pulse 1s infinite ease-in-out;
}
@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scale(0.8);
    opacity: 0.6;
  }
  50% {
    -webkit-transform: scale(1);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(0.8);
    opacity: 0.6;
  }
}

@keyframes pulse {
  0% {
    transform: scale(0.8);
    opacity: 0.6;
  }
  50% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(0.8);
    opacity: 0.6;
  }
}

.pulse-logo {
  -webkit-animation: pulse 1s infinite ease-in-out;
  -o-animation: pulse 1s infinite ease-in-out;
  -ms-animation: pulse 1s infinite ease-in-out;
  -moz-animation: pulse 1s infinite ease-in-out;
  animation: pulse 1s infinite ease-in-out;
}

.logo {
  padding: 0px;
  margin: 0px;
  left: 50%;
  width: 250px;
  height: 250px;
  position: relative;
}

.dot {
  width: 200px;
  height: 200px;
  right: 50px;
  background-color: #34495c;
  border-radius: 100%;
  position: relative;
}

#small {
  width: 25px;
  height: 25px;
  right: 10px;
  bottom: 10px;
}

#big {
  width: 60px;
  height: 60px;
  left: 80px;
  bottom: 60px;
}

#biggest {
  width: 90px;
  height: 90px;
}

.react-tags {
  position: relative;
  padding: 6px 0 0 6px;
  border: 1px solid #d1d1d1;
  border-radius: 1px;

  /* shared font styles */
  font-size: 1em;
  line-height: 1.2;

  /* clicking anywhere will focus the input */
  cursor: text;
}

.react-tags.is-focused {
  border-color: #b1b1b1;
}

.react-tags__selected {
  display: inline;
}

.react-tags__selected-tag {
  display: inline-block;
  box-sizing: border-box;
  margin: 0 6px 6px 0;
  padding: 6px 8px;
  border: 1px solid #d1d1d1;
  border-radius: 2px;
  background: #f1f1f1;

  /* match the font styles */
  font-size: inherit;
  line-height: inherit;
}

.react-tags__selected-tag:after {
  content: '\2715';
  color: #aaa;
  margin-left: 8px;
}

.react-tags__selected-tag:hover,
.react-tags__selected-tag:focus {
  border-color: #b1b1b1;
}

.react-tags__search {
  display: inline-block;

  /* match tag layout */
  padding: 7px 2px;
  margin-bottom: 6px;

  /* prevent autoresize overflowing the container */
  max-width: 100%;
}

@media screen and (min-width: 30em) {
  .react-tags__search {
    /* this will become the offsetParent for suggestions */
    position: relative;
  }
}

.react-tags__search input {
  /* prevent autoresize overflowing the container */
  max-width: 100%;

  /* remove styles and layout from this element */
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;

  /* match the font styles */
  font-size: inherit;
  line-height: inherit;
}

.react-tags__search input::-ms-clear {
  display: none;
}

.react-tags__suggestions {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
}

@media screen and (min-width: 30em) {
  .react-tags__suggestions {
    width: 240px;
  }
}

.react-tags__suggestions ul {
  margin: 4px -1px;
  padding: 0;
  list-style: none;
  background: white;
  border: 1px solid #d1d1d1;
  border-radius: 2px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
}

.react-tags__suggestions li {
  border-bottom: 1px solid #ddd;
  padding: 6px 8px;
}

.react-tags__suggestions li mark {
  text-decoration: underline;
  background: none;
  font-weight: 600;
}

.react-tags__suggestions li:hover {
  cursor: pointer;
  background: #eee;
}

.react-tags__suggestions li.is-active {
  background: #b7cfe0;
}

.react-tags__suggestions li.is-disabled {
  opacity: 0.5;
  cursor: auto;
}
